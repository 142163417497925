import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { ThemeProvider, DefaultTheme } from 'styled-components';

import StyledComponentsRegistry from '@/lib/registry';
import { setTheme } from '@/theme';
import { theme as prusaUiTheme } from '@/theme.prusaUi';
import GlobalStyles from '@/ui/styles/GlobalStyles';

export default function ThemeProviders({ children }) {
  // isMedical
  const muiTheme = setTheme(); //{ isMedical }

  // Create a theme object for styled-components with double type assertion
  const styledTheme = {
    ...muiTheme,
    colors: prusaUiTheme.colors,
    text: prusaUiTheme.text,
    custom: muiTheme.custom,
  } as unknown as DefaultTheme;

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <StyledComponentsRegistry>
          <ThemeProvider theme={styledTheme}>
            <GlobalStyles />
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StyledComponentsRegistry>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
